import styled from 'styled-components';

import { InnerLayout } from '../styles/Layouts';
import ResumeItem from './ResumeItem';
import SmallTitle from './SmallTitle';
import Title from './Title';

const Resume =()=>{
 const iconBriefcase = <i class="fa-solid fa-briefcase"></i>
 const iconSchool = <i class="fa-solid fa-graduation-cap"></i>
return (
   <ResumeStyled>
        <Title title={'Resume'} span={'Resume'}/>
       <InnerLayout >
        <div className='small-title'>
        <SmallTitle icon={iconSchool}  title={'Educational Qualifications'}/>
        </div>
        <div className='resume-content'>
        <ResumeItem degree={'ND Programme'} year={'2016 - 2018'}
        title={'Computer Science Degree'}
        subTitle={'Wolex Polytechnic'}
        text={'Wolex Polytechnic is a privately owned polytechnic located in Iwo-Ejigbo, Osun State, South West Nigeria.'}
        />
        <ResumeItem degree={'HND Programme'} year={'2019 - 2021'}
        title={'Computer Science Degree'}
        subTitle={'Wolex Polytechnic'}
        text={'Wolex Polytechnic is a privately owned polytechnic located in Iwo-Ejigbo, Osun State, South West Nigeria.'}
        />
        </div>
        <div className='small-title u-small-title-margin'>
        <SmallTitle icon={iconBriefcase}  title={'Working Experience'}/>
        </div>
        <div className='resume-content'>
        <ResumeItem  year={'2021 - 2022'}
        title={'Maths Teacher'}
        subTitle={'SuccessLand Int School'}
        text={'The foundation of individual and national development is foregrounded on sustainable, modern and effective learning environment.'}
        />
        </div>
       </InnerLayout>
   </ResumeStyled>
 )
}

const ResumeStyled = styled.section`
.small-title{
padding-bottom: 2rem;
}
.resume-content{
border-left: 2px solid var(--border-color);
}
.u-small-title-margin{
margin-top: 4rem;
}
`;

export default  Resume;