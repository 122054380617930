import React from 'react';

import styled from 'styled-components';

const ContactItemPage =({icon, title, contact1, contact2})=>{
return (
 <ContactItemStyled>
 <div className='left-content'>
  {
  icon
  }
 </div>
 <div className='right-content'>
       <h6>{title}</h6>
       <p>{contact1}</p>
       <p>{contact2}</p>
 </div>
 </ContactItemStyled>
 )
}

const ContactItemStyled = styled.div`
  padding: 1.5rem 1rem;
  background-color: var(--background-dark-grey);
  display: flex;
  align-items: center;
  @media screen and (max-width: 280px){
  padding: 0rem .4rem;
  padding-bottom: .5rem;
   }
  &:not(:last-child){
  margin-bottom: 2.5rem;
  }
  .left-content{
  padding: 1.5rem;
  border: 1px solid var(--border-color);
  font-size: 2rem;
  display: center;
  align-items: center;
  justify-content: center;
  margin-right: 1.5rem;
  @media screen and (max-width: 280px){
  padding: .7rem;
   }
  .fa-solid{
  font-size: 1.8rem;
  @media screen and (max-width: 280px){
  font-size: 1.2rem;
   }
  }
  }
  .right-content{
    display: flex;
  h6{
  color: var(--white-color);
  font-size: 1.2rem;
  padding: .4rem;
  }
  p{
  padding: .1rem 0;
  @media screen and (max-width: 280px){
  font-size: .7rem;
   }
  }
  }
`;

export default ContactItemPage;