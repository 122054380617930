import React from 'react';

import styled from 'styled-components';

import ImageSection from '../Components/ImageSection';
import ServicesSection from '../Components/ServicesSection';
import Title from '../Components/Title';
import { MainLayout } from '../styles/Layouts';

const AboutPage =({setNavToggle, navToggle})=>{
return (
  <MainLayout onClick={()=> setNavToggle(navToggle !== navToggle)} >
   <AboutPageStyled>
    <Title title={'About Me'} span={'About Me'}/>
    <ImageSection/>
    <ServicesSection/>
    {/* <ReviewsSection/> */}
   </AboutPageStyled>
  </MainLayout>
 )
}

const AboutPageStyled = styled.section`
@media screen and (max-width: 1000px){

}
`;

export default AboutPage;