import React from 'react';

import styled from 'styled-components';

const Title =({title, span})=>{
return (
 <TitleStyled>
  <h2>{title} <b><span>{span}</span></b></h2>
 </TitleStyled>
 )
}

const TitleStyled = styled.div`
   position: relative;
   h2{
   color: var(--white-color);
   font-size: 2.6rem;
   font-weight: 500;
   text-transform: uppercase;
   position: relative;
   padding-bottom: .8rem;
   @media screen and (max-width: 496px){
   font-size: 2.3rem;
   }
   @media screen and (max-width: 370px){
   font-size: 1.8rem;
   }
   &::before{
    content: "";
    position: absolute;
    bottom: 0;
   width: 7.4rem;
   height: .33rem;
   background-color: var(--background-light-color-2);
   border-radius: 15px;
   left: 0;
   @media screen and (max-width: 539px){
   bottom: .7rem;
   }
   }
   &::after{
    content: "";
    position: absolute;
    bottom: 0;
   width: 2.5rem;
   height: .33rem;
   background-color: var(--primary-color);
   border-radius: 15px;
   left: 0;
   @media screen and (max-width: 539px){
   bottom: .7rem;
   }
   }
   span{ 
   font-weight: 700;
   color: rgba(25, 29, 43, .44);
   font-size: 3.8rem;
   position: absolute;
   left: 0;
   top: 30%;
   z-index: -1;
   @media screen and (max-width: 620px){
   font-size: 3.5rem;
   }
   @media screen and (max-width: 496px){
   font-size: 2.5rem;
   }
   @media screen and (max-width: 370px){
   font-size: 1.5rem;
   }
   }
   }
`;

export default  Title;