import React from 'react';

import styled from 'styled-components';

import Title from '../Components/Title';
import blogs from '../Data/Blogs';
import {
  InnerLayout,
  MainLayout,
} from '../styles/Layouts';

const BlogsPage =({setNavToggle, navToggle})=>{
return (
 <MainLayout onClick={()=> setNavToggle(navToggle !== navToggle)} >
 <BlogsStyled>
  <Title title={'Blogs'} span={'Blogs'}/>
  <InnerLayout className={"blog-post"}>
  {
  blogs.map((blog)=>{
  return <div key={blog.id} className={"blog-item"}>
      <div className='image' >
       <img src={blog.image} alt=""/>
      </div>
      <div className='title'>
        <div className='title'>
         <a href={blog.link}>
          {blog.title}
         </a>
      </div>
      </div>
  </div>
  })
  }
  </InnerLayout>
 </BlogsStyled>
 </MainLayout>
 )
}

const BlogsStyled = styled.div`
.blog-post{
display: grid ;
grid-template-columns: repeat(2, 1fr) ;
grid-column-gap: 2rem ;
grid-row-gap: 3rem;
padding: 2rem 1rem;
@media screen and (max-width: 770px){
grid-template-columns: repeat(1, 1fr);
}
.return-blog{

}
.blog-item{
background-color: var(--background-dark-grey);
padding: 1rem 1rem;
}
.image{
width: 100%;
overflow: hidden;
padding-bottom: .5rem;
img{
width: 100%;
height: 90%;
object-fit: cover;
transition: all .4s ease-in-out;
&:hover{
cursor: pointer;
transform: rotate(5deg) scale(1.1);
}
}
}
.title{
   a{
  font-size: 1.5rem;
  color: var(--white-color);
  padding: 2rem 0;
  cursor: pointer;
  transition: all .4s ease-in-out;
  
  &:hover{
  color: var(--primary-color);
  }
  }
}
}
`;

export default  BlogsPage;