import React from 'react';

import Resume from '../Components/Resume';
import Skills from '../Components/Skills';
import { MainLayout } from '../styles/Layouts';

const ResumePage =({setNavToggle, navToggle})=>{
return (
 <MainLayout onClick={()=> setNavToggle(navToggle !== navToggle)}>
 <Skills/>
 <Resume/>
 </MainLayout>
 )
}
export default  ResumePage;