import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`

  /* :root{
  --primary-color: #007bff;
  --secondary-color: #6c757d;
  --background-dark-color: #10121A;
  --background-dark-grey: #191D2B;
  --border-color: #2e344e;
  --background-light-color: #F1F1F1;
  --background-light-color-2: rgba(3, 127, 255, .3);
  --white-color: #FFF;
  --font-light-color: #a4acc4;
  --font-dark-color: #313131;
  --font-dark-color-2: #151515;
  --sidebar-dark-color: #191D2B;
  } */
  
  .light-theme{
    --primary-color: #057fff;
  --secondary-color: #6c757d;
  --background-dark-color: #F1F1F1;
  --background-dark-grey: #e4e4e4;
  --border-color: #cbced8;
  --background-light-color: #F1F1F1;
  --background-light-color-2: rgba(3, 127, 255, .3);
  --white-color: #151515;
  --font-light-color: black;
  --font-dark-color: #313131;
  --font-dark-color-2: #151515;
  --sidebar-dark-color: #E4E4E4;
  }

  .dark-theme{
    --primary-color: #007bff;
  --secondary-color: #6c757d;
  --background-dark-color: #10121A;
  --background-dark-grey: #191D2B;
  --border-color: #2e344e;
  --background-light-color: #F1F1F1;
  --background-light-color-2: rgba(3, 127, 255, .3);
  --white-color: #FFF;
  --font-light-color: #a4acc4;
  --font-dark-color: #313131;
  --font-dark-color-2: #151515;
  --sidebar-dark-color: #191D2B;
  }

  /* import React from 'react';

const PortfolioPage =()=>{
return (
 <>
 </>
 )
}
export default  PortfolioPage; */

  *{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  list-style:none;
  text-decoration: none;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 1.2rem;
  }

  body {
  background-color: var(--background-dark-color);
  color: var(--font-light-color);
  }

  body::-webkit-scrollbar{
  width: 9px;
  background-color: #383838;
  }
  body::-webkit-scrollbar-thumb{
  border-radius: 10px;
  background-color: #6b6b6b;
  }
  body::-webkit-scrollbar-track{
  width: 9px;
  background-color: #383838;
  }
  a{
  font-family: inherit;
  color: inherit;
  font-size: inherit;
  font-size: 1.2rem;
  }
  h1{
  font-size: 2.9rem;
  color: var(--white-color);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  @media screen and (max-width: 518px){
  font-size: 3rem;
   }
   @media screen and (max-width: 280px){
  font-size: 2.5rem;
   }
   
  span{
  font-size: 2.9rem;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  @media screen and (max-width: 518px){
  font-size: 3rem;
   }
   @media screen and (max-width: 280px){
  font-size: 2.5rem;
   }
  }
  }
  span{
  color: var(--primary-color);
  }
  h6{
  color: var(--white-color);
  font-size: 1.2rem;
  padding-bottom: .6rem;
  }
  //Utilities
  .u-margin-bottom{
  margin-bottom: 4rem;
  }
  /* import React from 'react';

const PortfolioPage =()=>{
return (
 <>
 </>
 )
}
export default  PortfolioPage; */

// Floating Toggler

.light-dark-mode{
position: fixed;
right: 0;
top: 50%;
background-color: var(--background-light-color-2);
width: 6.5rem;
height: 2.5rem;
z-index: 15;
display: flex;
align-items: center;
justify-content: space-evenly;
.fa-solid{
display: flex;
align-items: center;
font-size: 1.4rem;
color: var(--white-color);
}
}

//Nav Toggler
 .ham-burger-menu{
position: absolute;
right: 5%;
top: 6%;
display: none;
z-index: 15;
.fa-solid{
font-size: 2rem;
}
}
@media screen and (max-width:1200px){
  .ham-burger-menu{
  display: block;
  }
  }
  @media screen and (max-width:280px){
  .ham-burger-menu{
  display: block;
  }
  }
p{
@media screen and (max-width: 280px){
  font-size: 1.1rem;
   }
}
.nav-toggle{
transform: translateX(0);
z-index: 20;
}
// Global Media Quaries

`;
export default GlobalStyle;