import React from 'react';

import styled from 'styled-components';

const ResumeItem =({year, title, subTitle, text, degree})=>{
return (
 <ResumeItemStyled>
  <div className='left-content'>
   <h4>{degree}</h4>
   <p>{year}</p>
  </div>
  <div className='right-content'>
   <h5>{title}</h5>
   <h6>{subTitle}</h6>
   <p>{text}</p>
  </div>
 </ResumeItemStyled>
 )
}

const ResumeItemStyled = styled.section`
display: flex;
h4{
@media screen and (max-width: 280px){
  font-size: 1rem;
}
}
@media screen and (max-width: 421px){
  p, h5, h6{
  font-size: 1rem;
  }
}
  @media screen and (max-width: 390px){
  p, h5, h6{
  font-size: .9rem;
  }
   }
   @media screen and (max-width: 280px){
  p, h5, h6{
  font-size: .7rem;
  }
}
&:not(:last-child){
padding-bottom: 3rem;
}
.left-content{
 width: 50%;
 padding-left: 20px;
 position: relative;
 @media screen and (max-width: 280px){
  width:40%;
}
 &::before{
 content: "";
 position: absolute;
 left: -10px;
 top: 5px;
 height: 15px;
 width: 15px;
 border-radius: 50%;
 border: 2px solid var(--border-color);
 background-color: var(--background-dark-color);
 p{
 display: inline-block;
 }
}
}
.right-content{
 padding-left: 5rem;
 position: relative;
 @media screen and (max-width: 280px){
  padding-left: 3rem;
 }
 &::before{
 content: '';
 position: absolute;
 left: 0;
 top: 15px;
 width: 3rem;
 height: 2px;
 background-color: var(--border-color);
 @media screen and (max-width: 280px){
  width: 1rem;
}
 @media screen and (max-width: 1000px){
 left: 1rem;
 }
 @media screen and (max-width: 350px){
 left: 1.2rem;
 }
 }
h5{
color: var(--primary-color);
font-size: 1.8rem;
padding-bottom: .4rem;
@media screen and (max-width: 421px){
 font-size: 1.2rem;
 }
 @media screen and (max-width: 390px){
 font-size: 1.1rem;
 }
 @media screen and (max-width: 280px){
 font-size: .9rem;
 }
}
h6{
padding-bottom: .6rem;
font-size: 1.3rem;
@media screen and (max-width: 421px){
 font-size: 1.2rem;
 }
 @media screen and (max-width: 390px){
 font-size: 1.1rem;
 }
 @media screen and (max-width: 280px){
 font-size: .9rem;
 }
}
}
`;

export default  ResumeItem;