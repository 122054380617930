import React, {
  useEffect,
  useState,
} from 'react';

import {
  Route,
  Routes,
} from 'react-router-dom';
import styled from 'styled-components';

import BlogPage from './Components/Blog';
import BlogPost from './Components/BlogPost';
import Sidebar from './Components/SideBar';
import img from './img/business-website-1.jpg';
import img1 from './img/css-website.png';
import img3 from './img/html-website.png';
import img2 from './img/js-website.png';
import AboutPage from './Pages/AboutPage';
import BlogsPage from './Pages/BlogsPage';
import ContactPage from './Pages/ContactPage';
import HomePage from './Pages/HomePage';
import ResumePage from './Pages/ResumePage';

function App() {
  const [theme, setTheme] = useState('light-theme');
   const [checked, setChecked] = useState();
   const [navToggle, setNavToggle] = useState(false);
   const [brightToggle, setBrightToggle] = useState(false);
   
   useEffect(()=>{
  document.documentElement.className = theme;
  }, [theme])

  const themeToggler = ()=>{
  if(theme === 'light-theme'){
    setTheme('dark-theme');
    setChecked(false)
  }else{
  setTheme('light-theme');
    setChecked(true)
  }
  }


  return (
    <div className='App'>
      <Sidebar navToggle={navToggle}/>
      <div className='theme'>
      <div className='light-dark-mode'>
      <div className='left-content'>
      <i className= {theme === 'light-theme' ? "fa-duotone fa-moon" : "fa-sharp fa-solid fa-sun"}></i>
      </div>
      <div className='right-content'>
       <i className= {theme === 'light-theme' ? "fa-solid fa-toggle-on" : "fa-solid fa-toggle-off"}  value="" 
       checked={checked}
       inputProps={{ 'arial-label':  '' }}
       size="medium"
      //  onClick={themeToggler}
       onClick={themeToggler}></i>
      </div>
      </div>
      </div>

      {/* <i class="fa-regular fa-light-switch-on"></i> */}

      <div className='ham-burger-menu'>
 <i className={navToggle ? "fas fa-times" : "fas fa-bars"} onClick={()=> setNavToggle(!navToggle)}></i>
</div>

      <MainContentStyled>
      <div className='lines'>
      <div className='line-1'></div>
      <div className='line-2'></div>
      <div className='line-3'></div>
      <div className='line-4'></div>
      </div>
      
          {/* //<i class="fa-solid fa-toggle-off"></i> */}
          
      <Routes>
      <Route path="/" element={<HomePage setNavToggle={setNavToggle}  navToggle={navToggle} />} exact />
      <Route path="/about" element={<AboutPage setNavToggle={setNavToggle}  navToggle={navToggle}/>} exact/>
      <Route path="/blogs" element={<BlogsPage setNavToggle={setNavToggle}  navToggle={navToggle} />} exact/>
      <Route path="/contact" element={<ContactPage setNavToggle={setNavToggle}  navToggle={navToggle} />} exact/>
      <Route path="/blogs-post" element={<BlogPost setNavToggle={setNavToggle}  navToggle={navToggle} />} exact/>
      {/* <Route path="/portfolios" element={<PortfolioPage setNavToggle={setNavToggle}  navToggle={navToggle}/>} exact/> */}
      <Route path="/resume" element={<ResumePage setNavToggle={setNavToggle}  navToggle={navToggle}/>} exact/>
      <Route path="/blog-first" element={<BlogPage setNavToggle={setNavToggle}  navToggle={navToggle} img={img3} text='Hypertext markup language (HTML) structures documents such as web pages by formatting the content available on the web pages and converting pure information into a graphically descriptive representation (Robbins, 2014). HTML was developed to a significant degree by Tim Berners-Lee (Walter, 2008) at the European Nuclear Research Center CERN in Geneva (European Organization for Nuclear Research, n.d.). HTML can be described as the language that ultimately gives web pages (and websites) the appearance and structure that is seen by end users.

HTML’s origin can be traced back to the development of the World Wide Web in the late 1990s (selfHTML, n.d.-b). One of the main goals of HTML was to create a description language that could run on any platform. At CERN, a way to efficiently distribute measurement data and research results was sought, so that, for example, the measurement data collected in Geneva could be analyzed by scientific institutions all over the world. For this, it was necessary to provide the raw data and a classical transmission protocol for adequate interpretation of the obtained results. This led to the birth of the internet, which resulted in the first version of the HTML specification on November 3, 1992 (selfHTML, n.d.-b).

HTML differs from the programming languages discussed so far in that it knows no logical command flow structures (such as loops), but describes how the result (the web page) should ultimately look. To describe the respective commands, we work in tags, which begin in each case, for example, with a tag in the form <h1> and end with a tag in the form </h1>. This syntax is very similar to the syntax used in XML. For example, an HTML document is always introduced as follows:

<!DOCTYPE html>
<html>
<head>
<title> Test Website </title>
</head>
<body>
<h1> Hello world </h1>
<p>This is my first HTML document </p>
<a href="http://www.google.com/">I am a link</a>
<img src="Image.jpg" alt="I am an image in an HTML document">
</body>
</html>
An HTML document always begins by declaring the document type. This ultimately serves to tell the browser what the document in question is about. This is an example of an HTML document, so the declaration can look like this: <!DOCTYPE html>. Then, the actual HTML elements follow. An HTML element always begins with a start tag in the form <…>, followed by the content of the respective tag and an end tag in the form </…>. In addition, an HTML document can have a header element, in which, for example, the title of a web page is defined. This will be displayed in the browser or the tab label of the browser. The actual content of an HTML document is listed in a body element.

For example, <h1> is used to create a bold heading, after which <p> is used to introduce a paragraph. An <a href> tag leads to the definition of a link (the tag here is actually just “a”, “href” is, strictly speaking, an attribute that describes the characteristics of the “a” tag: here, around the exact link). An <img> tag is used to embed an image into the HTML web page. The modern advancement of HTML to HTML5 has also enabled targeted animation control, the embedding of videos, and much more. If web pages need to be extended by logical structures and arithmetic operations, or, for example, database connections are desired, this cannot be realized with HTML alone and requires the use of PHP. The HTML tags <a>…</a>, i.e., opening and closing tags, are also called HTML elements. Selected HTML tags, along with their functions, can be seen in the following table (selfHTML n.d.-b).' title="Fundamentals of HTML5" author="Author" name="Jacek Jeznach"/>} />
<Route path="/blog-second" element={<BlogPage setNavToggle={setNavToggle}  navToggle={navToggle} img={img} text='why do you need a website?
Everyone who decides to create a website needs to first answer themselves a question, what do they need a website for? Having a goal will allow to determine the course of action, which is the particular type of website to invest in. We should specify the website’s target, or its audience, as well as their potential expectations. After all, it is our potential clients that we want to reach with our offer, which on the Internet is presented mainly on WWW pages. But let’s take a look at two different cases related to the purpose of a website.

Our company needs a virtual calling card online, but all that we really want is to have some information about us available on the Internet (information on the offer, on the company and contact info). The offer gets updated once in a while, which means the website’s content itself will not change very often, either.

In the second case we wish to work on the image, so that we can use the website to form lasting relationships with users, to make people start perceiving us as specialists in our field. The plans we have tied to the website are a little different than just informing people. We’re planning our website to have a “news” section, articles, and our offer to change often enough for us to need to be able to manage it on our own (so that we can quickly apply all changes at any moment). Personally managing the website or adding and removing content are things that can be done on websites equipped with a CMS, which is a Content Management System.' title="Designing and creating websites for business" author="Author" name="Jacek Jeznach"/>} />
      <Route path="/blog-third" element={<BlogPage setNavToggle={setNavToggle}  navToggle={navToggle} img={img1} text='Type Selectors
Type selectors target elements by their element type. For example, should we wish to tar-get all division elements, <div>, we would use a type selector of div. The following code shows a type selector for division elements as well as the corresponding HTML it selects.

Class Selectors
Class selectors allow us to select an element based on the element’s class attribute value. Class selectors are a little more speciﬁc than type selectors, as they select a par- ticular group of elements rather than all elements of one type.

Class selectors allow us to apply the same styles to different elements at once by using the same class attribute value across multiple elements.
Within CSS, classes are denoted by a leading period, ., followed by the class attribute value. Here the class selector will select any element containing the class attribute value of awesome, including both division and paragraph elements.

ID Selectors
ID selectors are even more precise than class selectors, as they target only one unique element at a time. Just as class selectors use an element’s class attribute value as the selector, ID selectors use an element’s id attribute value as a selector.

Regardless of which type of element they appear on, id attribute values can only be used once per page. If used they should be reserved for signiﬁcant elements.

Within CSS, ID selectors are denoted by a leading hash sign, #, followed by the id attri- bute value. Here the ID selector will only select the element containing the id attribute value of shayhowe.' title="CSS Selectors Cheat Sheet (.pdf)" author="Author" name="Jacek Jeznach"/>} />
      <Route path="/blog-fourth" element={<BlogPage setNavToggle={setNavToggle}  navToggle={navToggle} img={img2} text='What is JSX?
JSX is a superset of JavaScript that allows HTML to be included in code files without requiring any special quoting. JSX combines the JS from JavaScript and the X from XML. It is a JavaScript extension that allows us to define React elements using a tag-based syntax directly within our JavaScript code.

Thanks to JSX, without any extra templating syntax (except for the curly braces), we are able to use JavaScript in HTML. JSX was initially invented for React, but it became useful for other modern libraries and frameworks after it gained popularity.

The JSX files used in React development make it easy to mix HTML and JavaScript, but there are some important differences from regular HTML files.

In the example below text “Welcome to React world!” inside h4 tag is returned as JavaScript function to the render function. 
What are Pure Components?
React component is considered a Pure Component if it renders the same output for the same state and props value.

React provides the PureComponent base class for these class components. React.PureComponent is exactly the same as React.Component except that it handles the shouldComponentUpdate() method for you. When props or state changes, PureComponent will do a shallow comparison on both props and state. If updated values are the same as previous values, render is not triggered.' title="React JS Questions & Answers (Part 1) 2021" author="Author" name="Jacek Jeznach"/>} />
      </Routes>
      </MainContentStyled>
    </div>
  );
}



const MainContentStyled = styled.main`
position: relative;
margin-left: 16.3rem;
min-height: 100vh;
@media screen and (max-width:1200px){
  margin-left: 0;
  }
.lines{
position: absolute;
min-height: 100%;
width: 100%;
display: flex;
justify-content: space-evenly;
display: none;
.line-1, .line-2, .line-3, .line-4{
width: 1px;
min-height: 100vh;
background-color: var(--border-color);
}
}
`;
export default App;
