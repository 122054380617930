import blog2 from '../img/web-blog2.jpg';
import blog3 from '../img/web-blog3.jpg';
import blog4 from '../img/web-blog4.jpg';
import blog1 from '../img/website-developer6.jpg';

const blogs = [
 {
     id: 1,
     title: 'WEB DEVELOPMENT ARTICLE',
     date: '02',
     month: 'February',
     image: blog1,
     link: '/blog-first'
},
{
     id: 2,
     title: 'CREATING WEBSITE ARTICLE',
     date: '02',
     month: 'February',
     image: blog2,
     link: '/blog-second'
},
{
     id: 3,
     title: 'HTML & CSS',
     date: '02',
     month: 'February',
     image: blog3,
     link: '/blog-third'
},
{
     id: 4,
     title: 'JAVASCRIPT',
     date: '02',
     month: 'February',
     image: blog4,
     link: '/blog-fourth'
}
]
export default blogs;