import React from 'react';

import styled from 'styled-components';

import blogs from '../Data/Blogs';

const BlogPost = () =>{
 {
  blogs.map((blog)=>{
return(
 <div className='title'>
         <a href={blog.link}>
          {blog.title}
         </a>
      </div>
 )
 })
 }
}
const BlogsStyled = styled.div`

`;
export default BlogPost;