import React from 'react';

import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import {
  BlogInnerLayout,
  InnerLayout,
} from '../styles/Layouts';
import Title from './Title';

const BlogPage =({img, title, author,text, setNavToggle, navToggle, name})=>{
return (
 <BlogInnerLayout onClick={()=> setNavToggle(navToggle !== navToggle)}>
 <BlogPageStyled>
 <Title title={'Blog Post'} span={'Blog Post'}/>
 < InnerLayout>
 <div className='return-blog'>
      <NavLink to="/blogs" exact ><i class="fa-solid fa-arrow-left"></i>Return to blog</NavLink>
    </div>

  <div className='singlePost'>
   <div className='singlePostWrapper'>
   <img src={img} alt="" className='singlePostImage'/>
   <h1 className='singlePostTitle'>
   {title}
   </h1>
   <div className='singlePostInfo'>
    <h1 className='singlePostAuthor'>{author} : <span>{name}</span></h1>
   </div>
   <p className='postText'>
   {text}
   </p>
   </div>
  </div>

  <div className='return-blog'>
      <NavLink to="/blogs" exact ><i class="fa-solid fa-arrow-left"></i>Return to blog</NavLink>
    </div>
 </InnerLayout>
 </BlogPageStyled>
 </BlogInnerLayout>
 )
}

const BlogPageStyled = styled.div`
.singlePostWrapper{

}
.fa-arrow-left{
padding-right: .4rem;
}
.singlePostImage{
width: 100%;
height: 300px;
border-radius: 5px;
object-fit: cover;
}
.return-blog{
margin: 1rem;
color: var(--primary-color);
display: inline-block;
font-weight: bold;
}
.singlePostTitle{
text-align: center;
margin: 10px;
font-family: 'lora', serif;
font-size: 48px;
@media screen and (max-width: 395px){
font-size: 35px;
}
@media screen and (max-width: 420px){
font-size: 40px;
}
@media screen and (max-width: 290px){
font-size: 27px;
}
}
.singlePostInfo{
margin-bottom: 20px;
display: flex;
font-size:16px;
h1{
font-size:25px;
@media screen and (max-width: 290px){
font-size: 21px;
}
}
span{
font-size:25px;
@media screen and (max-width: 290px){
font-size: 21px;
}
}
}
.postText{
font-size: 22px;
&::first-letter{
margin-left: 1.4rem;
font-size: 30px;
font-weight: 600;
font-family: Georgia, 'Times New Roman', Times, serif;
}
@media screen and (max-width: 420px){
font-size: 20px;
line-height: 25px;
}
}
`;

export default  BlogPage;