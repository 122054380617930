import React from 'react';

import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import person from '../img/carie1.webp';

const Navigation = ({setNavToggle, navToggle})=> {
 return (
  <NavigationStyled>
  <div className='person'>
   <img src={person} alt=''/>
  </div>
  <ul className='nav-items' >
  <li className='nav-item' >
  <NavLink onClick={()=> setNavToggle(navToggle !== navToggle)}  activeClassName="active"  to="/" exact  >Home</NavLink>
  </li>
  <li className='nav-item'>
  <NavLink onClick={()=> setNavToggle(navToggle !== navToggle)} activeClassName="active" to="/about" exact >About</NavLink>
  </li>
  <li className='nav-item'>
  <NavLink onClick={()=> setNavToggle(navToggle !== navToggle)} activeClassName="active-class" to="/resume" exact>Resume</NavLink>
  </li>
  {/* <li className='nav-item'>
  <NavLink activeClassName="active" to="/portfolios" exact>Portfolios</NavLink>
  </li> */}
  <li className='nav-item'>
  <NavLink onClick={()=> setNavToggle(navToggle !== navToggle)} activeClassName="active" to="/blogs" exact >Blogs</NavLink>
  </li>
  <li className='nav-item'>
  <NavLink onClick={()=> setNavToggle(navToggle !== navToggle)} activeClassName="active" to="/contact" exact >Contact</NavLink>
  </li>
  </ul>
  <footer className='footer'>
   <p>@2023 My Portfolio Website</p>
  </footer>
  </NavigationStyled>
  )
}

const NavigationStyled = styled.nav`
display: flex;
justify-content:space-between;
flex-direction: column;
align-items: center;
height: 100%;
width: 100%;
border-right: 1px solid var(--border-color);
@media screen and (max-width: 375px){ 
  width: 100%;
  }
.person {
 width: 80%;
 border-bottom: 1px solid var(--border-color);
 text-align: center;
 padding: 1rem 0;
 img{
 width: 100%;
 border-radius: 50%;
 border: 8px solid var(--border-color);
 }
}
.nav-items{
 width: 100%;
 text-align: center;
 .active{
 background-color: var(--primary-color);
 color: var(--white-color);
 }
 li{
 display: block;
 a{
 display: block;
 padding: .25rem 0;
 position:relative;
 z-index: 10;
 text-transform: uppercase;
 &:hover{
 cursor: pointer;
 color: var(--white-color);
 }
 &::before{
 content: "";
 position: absolute;
 bottom:0;
 left: 0;
 width: 0;
 height: 50%;
 background-color: var(--primary-color);
 transition: all 0.4s cubic-bezier(1, -0.2,.25,.95);
 z-index: -1;
 opacity: 0.21;
 }
 }
 a:hover::before{ 
 width: 100%;
 height: 100%;
 }
 }
}
footer{
 border-top: 1px solid var(--border-color);
 width:100%;
 p{
 padding:1.5rem 0;
 font-size:1.1rem;
 display:block;
 text-align: center;
 }
 }
`;

export default Navigation;