import React, {
  useRef,
  useState,
} from 'react';

import { motion } from 'framer-motion';
import styled from 'styled-components';

import emailjs from '@emailjs/browser';

import ContactItemPage from '../Components/ContactItem';
import Title from '../Components/Title';
import {
  InnerLayout,
  MainLayout,
} from '../styles/Layouts';

const ContactPage = ({setNavToggle, navToggle})=>{ 
  const iconPhone = <i class="fa-sharp fa-solid fa-phone"></i>
  const iconEmail = <i class=" fa-solid fa-envelope"></i>
  const iconLocation = <i class=" fa-solid fa-location-dot"></i>
  
  const transition = {duration: 2, type: 'spring'}


   const form = useRef();

   const [done, setDone] = useState(false)

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_ne8acnh', 'template_jx9qxle', form.current, 'Wrh-bGwozPneieER5')
      .then((result) => {
          console.log(result.text);
          setDone(true)
      }, (error) => {
          console.log(error.text);
      });
  };
  return ( 
       <MainLayout onClick={()=> setNavToggle(navToggle !== navToggle)}> 
        <Title title={'Contact'} span={'Contact'} />
        <ContactPageStyled>   
        <InnerLayout className={'contact-section'}>
        <div className='left-content'>
          <div className='contact-title'>
            <h4>Get In Touch</h4>
            <span className='contact-me'>Contact Me</span>
          </div>
          <form className='form'  ref={form} onSubmit={sendEmail}>
            <div className='form-field'>
             <label htmlFor='name'>Enter your name*</label>
             <input type='text' id='name' name="user_name"/>
            </div>
            <div className='form-field'>
             <label htmlFor='email'>Enter your email*</label>
             <input type='email' id='email' name="user_email" />
            </div>
            <div className='form-field'>
             <label htmlFor='subject'>Enter your subject</label>
             <input type='text' id='subject' name="from_name"/>
            </div>
            <div className='form-field'>
              <label htmlFor='text-area'>Enter your Message*</label>
    <textarea name='message' id='texatea' cols="30" rows="10"    ></textarea>
    </div>
    <div className='form-field f-button'>
    <input className="send-email" title={'Send Email'} type="submit" value="Send Email"/>
    </div>
    {done && <motion.div 
animate={{ x: [0, 100, 0], opacity: 1, scale: 1 }}
                transition={{
                    duration: 2,
                    delay: 0.3,
                    ease: [0.5, 0.71, 1, 1.5],
                }}
                initial={{ opacity: 1, scale: 0.5 }}
    className="response-email">"Thanks for contacting me!!"</motion.div>}
   </form>
  </div>
   <div className='right-content'>
   <ContactItemPage icon={iconPhone} title={'Phone'} contact1={'+2348056252144'} contact2={'+2348056252144'} />
   {/* <ContactItemPage icon={iconEmail} title={'Email'} contact1={'olutomidamilare@gmail.com'} contact2={'draydan6062@gmail.com'} />
   <ContactItemPage icon={iconLocation} title={'Address'} contact1={'No 26, Bakare St Idimu, Lagos'} contact2={'Nigeria'} /> */}
  </div> 
  </InnerLayout>
 </ContactPageStyled>
 </MainLayout>
 )
}

const ContactPageStyled = styled.section`
@media screen and (max-width: 280px){
   padding: 1rem .4rem;
   }
.contact-section{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 2rem;
  @media screen and (max-width: 978px){
grid-template-columns: repeat(1, 1fr);
}
.contact-me{
font-size: 2rem;
font-weight: 600;
}
.response-email{
background-color: var(--primary-color);
color: var(--white-color);
border: 1px solid var(--border-color);
padding: .7rem;
font-size: 1.1rem;
display: inline-block;
@media screen and (max-width: 1340px){
font-size: 1rem;
}
}
.send-email{
cursor: pointer;
}
.f-button{
  margin-bottom: 3rem;
background: var(--primary-color);
color: var(--white-color);
 width: 70%;
 cursor: pointer;
 font-size: inherit;
 text-transform: uppercase;
 position: relative;
 transition: all .4s ease-in-out;
 &::after{
 content: "";
 position: absolute;
 width: 0;
 height: .2rem;
  transition: all .4s ease-in-out;
  left: 0;
  bottom: 0;
  opacity: .7;
}
 &:hover::after{
 width: 100%;
 background-color: var(--white-color);
 }
}

  .right-content{
  display: flex;
  flex-direction: column;
  margin-top: 2rem; 
  @media screen and (max-width: 280px){
  width: 100%;
   }
  }
  .contact-title{
  h4{
  color: var(--white-color);
  padding: 1rem 0;
  font-size: 1.8rem;
  }
  }
  .form{
   width: 100%;
   @media screen and (max-width: 518px){
   width: 70%;
   }
  .form-field{
  margin-top: 2rem;
  position: relative;
  width: 100%;
  label{
  position: absolute;
  left: 20px;
  top: -19px;
  display: inline-block;
  background-color: var(--background-dark-color);
  padding: 0 .5rem;
  color: inherit;
  @media screen and (max-width: 280px){
  font-size: .9rem;
  left: 16px;
  
   }
  }
  input{
  border: 1px solid var(--border-color);
  outline: none;
  background: transparent;
  height: 3.0rem;
  padding: 0 15px;
  width: 100%;
  color: inherit;
  }
  textarea{
  background-color: transparent;
  border: 1px solid var(--border-color);
  outline: none;
  color: inherit;
  width: 100%;
  padding: .8rem 1rem;
  height: 16rem;
  width: 16rem;
  @media screen and (max-width: 280px){
  width: 12rem;
  height: 12rem;
  padding: 1rem;
   }
  }
  }
  }
}
`;

export default  ContactPage;
