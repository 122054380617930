import React from 'react';

import { motion } from 'framer-motion';
import styled from 'styled-components';

import gamedev from '../img/web-developer1.png';
import design from '../img/website-developer8.png';
import { InnerLayout } from '../styles/Layouts';
import ServiceCard from './ServiceCard';
import Title from './Title';

const ServicesSection =()=>{

 const transition = {duration: 2, ease: 'easeInOut'}

return (
 
 <InnerLayout>
  <ServicesSectionStyled>
   <Title title={'Services'} span={'Services'}/>
   <motion.div 
     animate={{ x: [0, 150, 0], opacity: 1, scale: 1 }}
                transition={{
                    duration: 2,
                    delay: 0.3,
                    ease: [0.5, 0.71, 1, 1.5],
                }}
                initial={{ opacity: 1, scale: 0.5 }}
   
   className='services'>
   {/* <ServiceCard
   image={design}
   // title={'Web Design'}
   // paragraph={'David wasn’t God’s first choice, yet look how powerfully God used him.'}
   /> */}
   <ServiceCard 
   image={gamedev}
   title={'Web Development'}
   paragraph={'We build and maintain websites; we make a website look great, work fast and perform well with a seamless user experience.'}
   />
   <div className="image">
    <img src={design} alt='' />
   </div>
   </motion.div>
  </ServicesSectionStyled>
 </InnerLayout>
 )
}

const ServicesSectionStyled = styled.section`
.image{
  img{
  width: 100%;
  }
}
.services{
 margin-top: 3.5rem;
display: grid;
grid-template-columns: repeat(3, 1fr);
grid-gap: 2rem;
@media screen and (max-width: 1000px){
flex-direction: column;
}
@media screen and (max-width: 950px){
grid-template-columns: repeat(2, 1fr);
}
@media screen and (max-width: 650px){
grid-template-columns: repeat(1, 1fr);
}

}
`;

export default ServicesSection;